import React, { Component } from "react";
import Konva from "konva";
import api from "api";
import axios from "axios";
import { Link } from "react-router-dom";
import uuid from "uuid/v1";
import "../styles.scss";

class DetailPage extends Component {
  constructor() {
    super();

    this.state = {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      manifest: null,
      name: "",
      processed: false,
      chunks: [],
      groups: [],
      pins: [],
      activePin: null,
      addingPinMode: false,
      pinName: "",
      pinNameFailure: false,
      activeTab: "layers"
    };
  }

  async componentDidMount() {
    await this.loadingData();

    if (this.state.processed) {
      this.updateCanvas();
    }
  }

  setGroups(groups) {
    const groupsList = groups.map(group => ({
      index: group.index,
      name: group.name,
      isVisible: true
    }));

    this.setState({
      groups: groupsList
    });
  }

  async loadingData() {
    let dataSuccessLoading = true;
    const itemId = this.props.match.params.id;

    this.setState({
      isPending: true,
      isSuccess: false,
      isFailure: false
    });

    await api(`plans/${itemId}`)
      .then(response => {
        this.setState({
          manifest: {
            ...response.data.data.manifest
          },
          processed: response.data.data.processed,
          name: response.data.data.name
        });

        if (response.data.data.manifest) {
          this.setGroups(response.data.data.manifest.layers);
        }
      })
      .catch(e => {
        dataSuccessLoading = false;

        this.setState({
          isPending: false,
          isSuccess: false,
          isFailure: true
        });
      });

    if (this.state.manifest) {
      const getChunksPromises = [];
      for (let i = 0; i < this.state.manifest.chunks; i++) {
        getChunksPromises[i] = axios(
          `${this.state.manifest.dirPath}drawing-chunk${i + 1}.json`
        )
          .then(response => {
            this.setState(state => ({
              chunks: [...state.chunks, [...response.data]]
            }));
          })
          // eslint-disable-next-line
          .catch(e => {
            dataSuccessLoading = false;

            this.setState({
              isPending: false,
              isSuccess: false,
              isFailure: true
            });
          });
      }

      await Promise.all([
        ...getChunksPromises,
        api(`plan/${itemId}/comments/`)
          .then(response => {
            this.setState({
              pins: [...response.data.data]
            });
          })
          .catch(e => {
            dataSuccessLoading = false;

            this.setState({
              isPending: false,
              isSuccess: false,
              isFailure: true
            });
          })
      ]);
    }

    if (dataSuccessLoading) {
      this.setState({
        isPending: false,
        isSuccess: true,
        isFailure: false
      });
    }
  }

  updateCanvas() {
    const { manifest, chunks, groups, pins } = this.state;

    const stageWidth = manifest.info.width;
    const stageHeight = manifest.info.height;

    this.stage = new Konva.Stage({
      container: "canvas",
      width: stageWidth,
      height: stageHeight
    });

    this.layer = new Konva.Layer();

    let canvasGroups = {};
    groups.forEach(group => {
      canvasGroups[group.index] = new Konva.Group({
        id: group.index,
        name: group.name,
        visible: group.isVisible
      });
    });

    chunks.forEach(chunk => {
      chunk.forEach(item => {
        item.figures.forEach(figure => {
          if (figure.components) {
            const shape = new Konva.Shape({
              sceneFunc: function(context) {
                context.beginPath();

                figure.components.forEach(drawCommand => {
                  switch (drawCommand.type) {
                    case "MOVE_TO":
                      context.moveTo(drawCommand.point.x, drawCommand.point.y);
                      break;
                    case "LINE_TO":
                      context.lineTo(drawCommand.point.x, drawCommand.point.y);
                      break;
                    case "CURVE_TO":
                      if (drawCommand.points.length === 2) {
                        context.quadraticCurveTo(
                          drawCommand.points[0].x,
                          drawCommand.points[0].y,
                          drawCommand.points[1].x,
                          drawCommand.points[1].y
                        );
                      } else if (drawCommand.points.length === 3) {
                        context.bezierCurveTo(
                          drawCommand.points[0].x,
                          drawCommand.points[0].y,
                          drawCommand.points[1].x,
                          drawCommand.points[1].y,
                          drawCommand.points[2].x,
                          drawCommand.points[2].y
                        );
                      }
                      break;
                    default:
                      break;
                  }
                });

                context.fillStrokeShape(this);
              },
              stroke:
                figure.parameters && figure.parameters.color
                  ? figure.parameters.color
                  : null,
              strokeWidth: 0.8,
              strokeScaleEnabled: false,
              lineCap:
                figure.parameters && figure.parameters["start-cap"]
                  ? figure.parameters["start-cap"]
                  : null,
              lineJoin:
                figure.parameters && figure.parameters["line-join"]
                  ? figure.parameters["line-join"]
                  : null
            });

            canvasGroups[item.layerId].add(shape);
          }

          if (figure.text) {
            let fontStyle = null;

            if (figure.font.style.italic) {
              fontStyle = "italic";
            }

            if (figure.font.style.bold) {
              fontStyle = "bold";
            }

            var text = new Konva.Text({
              x: parseFloat(figure.position.x),
              y: parseFloat(figure.position.y),
              text: figure.text,
              fontSize: parseFloat(figure.font.size),
              fontFamily: figure.font.name,
              fill: figure.font.color,
              rotation: parseFloat(figure.font.rotation),
              scaleX: parseFloat(figure.font["width-scale"]),
              fontStyle,
              lineHeight: 0
            });

            canvasGroups[item.layerId].add(text);
          }
        });
      });
    });

    this.pinsGroup = new Konva.Group();

    this.pinDraw = new Konva.Circle({
      radius: 10,
      fill: "red",
      stroke: "black",
      strokeWidth: 1
    });

    this.pinDraw.cache();

    let clonePin;
    pins.forEach(pin => {
      clonePin = this.pinDraw.clone({
        id: pin.uuid,
        x: parseFloat(pin.x),
        y: parseFloat(pin.y)
      });

      clonePin.cache();
      this.pinsGroup.add(clonePin);
    });

    for (var groupId in canvasGroups) {
      this.layer.add(canvasGroups[groupId]);
    }

    this.pinsGroup.on("click", e => {
      const { pins } = this.state;
      const pin = pins.find(pin => pin.uuid === e.target.id());
      this.setState({
        activePin: pin
      });
    });

    this.pinsGroup.on("tap", e => {
      const { pins } = this.state;
      const pin = pins.find(pin => pin.uuid === e.target.id());
      this.setState({
        activePin: pin
      });
    });

    this.pinsGroup.on("mouseenter", () => {
      this.stage.container().style.cursor = "pointer";
    });

    this.pinsGroup.on("mouseleave", () => {
      this.stage.container().style.cursor = "default";
    });

    this.stage.on("click", e => {
      var pos = this.getRelativePointerPosition(this.stage);
      this.drawPin(pos);
    });

    this.stage.on("tap", e => {
      var pos = this.getRelativePointerPosition(this.stage);
      this.drawPin(pos);
    });

    this.layer.add(this.pinsGroup);

    this.stage.add(this.layer);
    this.layer.draw();

    const fitStageIntoParentContainer = () => {
      var container = document.querySelector("#stage-parent");

      // now we need to fit stage into parent
      var containerWidth = container.offsetWidth;
      // to do this we need to scale the stage
      var scale = containerWidth / stageWidth;

      this.stage.width(stageWidth * scale);
      this.stage.height(stageHeight * scale);
      this.stage.scale({ x: scale, y: scale });
      this.stage.draw();
    };

    fitStageIntoParentContainer();
    window.addEventListener("resize", fitStageIntoParentContainer);

    // const canvas = document.querySelector("#canvas canvas");

    // canvas.onmousedown = e => {
    //   const moveAt = (pageX, pageY) => {
    //     canvas.style.transform = `translate(${pageX - shiftX}px, ${pageY -
    //       shiftY}px)`;
    //   };

    //   let shiftX = e.clientX - canvas.getBoundingClientRect().left;
    //   let shiftY = e.clientY - canvas.getBoundingClientRect().top;
    //   moveAt(e.pageX, e.pageY);

    //   const onMouseMove = e => {
    //     moveAt(e.pageX, e.pageY);
    //   };

    //   const drawCanvas = e => {
    //     const currentPos = this.stage.position();
    //     this.stage.position({
    //       x: currentPos.x + (e.pageX - shiftX),
    //       y: currentPos.y + (e.pageY - shiftY)
    //     });
    //     this.stage.draw();
    //     canvas.style.transform = "";
    //   };

    //   document.addEventListener("mousemove", onMouseMove);

    //   canvas.onmouseup = e => {
    //     drawCanvas(e);
    //     document.removeEventListener("mousemove", onMouseMove);
    //     canvas.onmouseup = null;
    //   };
    // };

    // canvas.ondragstart = () => {
    //   return false;
    // };
  }

  toggleVisibleGroup(id) {
    const { groups } = this.state;

    let isVisible = true;

    const newGroups = groups.map(group => {
      if (group.index === id) {
        isVisible = !group.isVisible;

        return {
          ...group,
          isVisible
        };
      }

      return {
        ...group
      };
    });

    this.setState({
      groups: [...newGroups]
    });

    this.stage.findOne(`#${id}`).visible(isVisible);
    this.stage.draw();
  }

  toggleAddingPinMode() {
    this.setState(state => ({
      addingPinMode: !state.addingPinMode
    }));
  }

  getRelativePointerPosition(node) {
    var transform = node.getAbsoluteTransform().copy();
    transform.invert();

    var pos = node.getStage().getPointerPosition();

    return transform.point(pos);
  }

  drawPin(position) {
    const { addingPinMode, pinName } = this.state;

    if (!addingPinMode) {
      return;
    }

    if (!pinName) {
      this.setState({
        pinNameFailure: true
      });

      return;
    }

    const id = uuid();

    const clonePin = this.pinDraw.clone({
      id: id,
      x: position.x,
      y: position.y
    });

    clonePin.cache();
    this.pinsGroup.add(clonePin);
    this.layer.draw();

    this.savePin(id, pinName, position);
    this.handlerOnClickPinNameCancel();
  }

  savePin(id, name, position) {
    const itemId = this.props.match.params.id;

    let bodyFormData = new FormData();

    bodyFormData.set("name", name);
    bodyFormData.set("x", position.x);
    bodyFormData.set("y", position.y);
    bodyFormData.set("uuid", id);
    bodyFormData.append("attach_file", this.pinFileInput.files[0]);

    api({
      method: "post",
      url: `plan/${itemId}/comments/`,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    }).then(response => {
      api(`plan/${itemId}/comments/`)
        .then(response => {
          this.setState({
            pins: [...response.data.data]
          });
        })
        .catch(e => {
          this.setState({
            isPending: false,
            isSuccess: false,
            isFailure: true
          });
        });
    });
  }

  handlerChangePinName() {
    const value = this.pinNameInput.value;

    this.setState({
      pinName: value,
      pinNameFailure: false
    });
  }

  handlerOnClickPinNameCancel() {
    this.toggleAddingPinMode();

    this.setState({
      pinName: "",
      pinNameFailure: false
    });
  }

  handlerChangeTab(activeTab) {
    this.setState({
      activeTab
    });
  }

  async reloadPage() {
    await this.loadingData();

    if (this.state.processed) {
      this.updateCanvas();
    }
  }

  render() {
    const {
      isPending,
      isSuccess,
      isFailure,
      groups,
      activePin,
      addingPinMode,
      pinName,
      pinNameFailure,
      name,
      processed,
      activeTab
    } = this.state;

    if (isPending) return <div className="loading">Загрузка...</div>;
    if (isFailure) return <div className="failure">Ошибка загрузки данных</div>;
    if (!processed) {
      return (
        <div className="draw-detail">
          <div className="draw-detail__wrapper">
            <div className="draw-detail__filter">
              <Link className="draw-detail__back-btn" to="/">
                <svg
                  width="21"
                  height="14"
                  viewBox="0 0 21 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 7a1 1 0 0 0-1-1H3.8l3.9-3.9A.99.99 0 1 0 6.3.7L0 7l6.3 6.3a.99.99 0 1 0 1.4-1.4L3.8 8H20a1 1 0 0 0 1-1z"
                    fill="#00AEEA"
                  />
                </svg>
                Назад к списку документов
              </Link>
              <h1>{name}</h1>
              <div className="draw-detail__processed">
                <svg
                  width="33"
                  height="24"
                  viewBox="0 0 33 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 6l-6 6h4.5c0 4.965-4.035 9-9 9a8.806 8.806 0 0 1-4.2-1.05l-2.19 2.19A11.896 11.896 0 0 0 16.5 24c6.63 0 12-5.37 12-12H33l-6-6zM7.5 12c0-4.965 4.035-9 9-9 1.515 0 2.955.375 4.2 1.05l2.19-2.19A11.896 11.896 0 0 0 16.5 0c-6.63 0-12 5.37-12 12H0l6 6 6-6H7.5z"
                    fill="#00AEEA"
                  />
                </svg>
                <div className="draw-detail__processed-text">
                  Файл еще обрабатывается
                </div>
                <button
                  type="button"
                  className="btn btn--block"
                  onClick={this.reloadPage.bind(this)}
                >
                  Обновить
                </button>
              </div>
            </div>

            <div className="draw-detail__draw-wrapper"></div>
          </div>
        </div>
      );
    }
    if (isSuccess) {
      let groupsListTemplate = null;
      if (groups.length) {
        groupsListTemplate = groups.map(group => (
          <li key={group.index} className="draw-detail__layers-item">
            <label>
              <input
                type="checkbox"
                name={group.index}
                checked={group.isVisible}
                onChange={this.toggleVisibleGroup.bind(this, group.index)}
              />
              {group.name}
            </label>
          </li>
        ));
      }

      let pinContainerTemplate = (
        <div className="draw-detail__pin-name">
          Нажмите на красную точку на схеме, чтобы посмотреть замечание
        </div>
      );
      if (activePin) {
        pinContainerTemplate = (
          <div className="draw-detail__pin-name draw-detail__pin-name--active">
            <div className="draw-detail__pin-name-text">{activePin.name}</div>
            {activePin.file ? (
              <img
                src={activePin.file}
                className="draw-detail__pin-name-image"
                alt=""
              />
            ) : null}
          </div>
        );
      }

      let addingPinTemplate = null;
      if (addingPinMode) {
        addingPinTemplate = (
          <div className="draw-detail__add-pin-form form">
            <div className="form__item">
              <label
                htmlFor="name"
                className="form__label form__label--no-uppercase"
              >
                Напишите замечание и поставьте точку на схеме
              </label>
              <input
                id="name"
                ref={ref => (this.pinNameInput = ref)}
                type="text"
                placeholder="Ваше замечание"
                value={pinName}
                onChange={this.handlerChangePinName.bind(this)}
              />
              {pinNameFailure ? (
                <div className="form__error">Сначала введите замечание</div>
              ) : null}
            </div>
            <div className="form__item">
              <input ref={ref => (this.pinFileInput = ref)} type="file" />
            </div>
            <button
              type="button"
              onClick={this.handlerOnClickPinNameCancel.bind(this)}
              className="btn btn--cancel"
            >
              Отмена
            </button>
          </div>
        );
      } else {
        addingPinTemplate = (
          <button
            type="button"
            className="btn btn--link"
            onClick={this.toggleAddingPinMode.bind(this)}
          >
            + Добавить новое замечание
          </button>
        );
      }

      let tabTemplate = null;
      if (activeTab === "layers") {
        tabTemplate = (
          <ul className="draw-detail__layers-list">{groupsListTemplate}</ul>
        );
      } else if (activeTab === "pins") {
        tabTemplate = (
          <div>
            {pinContainerTemplate}
            {addingPinTemplate}
          </div>
        );
      }

      return (
        <div className="draw-detail">
          <div className="draw-detail__wrapper">
            <div className="draw-detail__filter">
              <Link className="draw-detail__back-btn" to="/">
                <svg
                  width="21"
                  height="14"
                  viewBox="0 0 21 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 7a1 1 0 0 0-1-1H3.8l3.9-3.9A.99.99 0 1 0 6.3.7L0 7l6.3 6.3a.99.99 0 1 0 1.4-1.4L3.8 8H20a1 1 0 0 0 1-1z"
                    fill="#00AEEA"
                  />
                </svg>
                Назад к списку документов
              </Link>
              <h1>{name}</h1>
              <div className="tabs">
                <div className="tabs__switcher">
                  <button
                    type="button"
                    className={`tabs__switch-btn ${
                      activeTab === "layers" ? "tabs__switch-btn--active" : null
                    }`}
                    onClick={this.handlerChangeTab.bind(this, "layers")}
                  >
                    Слои
                  </button>
                  <button
                    type="button"
                    className={`tabs__switch-btn ${
                      activeTab === "pins" ? "tabs__switch-btn--active" : null
                    }`}
                    onClick={this.handlerChangeTab.bind(this, "pins")}
                  >
                    Замечания
                  </button>
                </div>
                {tabTemplate}
              </div>
            </div>

            <div className="draw-detail__draw-wrapper">
              <div id="stage-parent">
                <div id="canvas" style={{ overflow: "hidden" }} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default DetailPage;
