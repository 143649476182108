import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

// Pages
import ListPage from "pages/list/containers";
import DetailPage from "pages/detail/containers";

function AppRouter() {
  return (
    <Router>
      <Route path="/" exact component={ListPage} />
      <Route path="/:id" exact component={DetailPage} />
    </Router>
  );
}

export default AppRouter;
