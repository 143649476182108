import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "api";

import "../styles.scss";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      list: [],
      isNameInvalidate: false,
      isFileInvalidate: false
    };
  }

  async componentDidMount() {
    await this.getList();
  }

  async getList() {
    this.setState({
      isPending: true,
      isSuccess: false,
      isFailure: false
    });

    await api("plans")
      .then(response => {
        this.setState({
          isPending: false,
          isSuccess: true,
          isFailure: false,
          list: response.data.data
        });
      })
      .catch(e => {
        this.setState({
          isPending: false,
          isSuccess: false,
          isFailure: true
        });
      });
  }

  handlerSubmitNewPlan() {
    let isValid = true;

    if (!this.inputName.value) {
      this.setState({
        isNameInvalidate: true
      });
      isValid = false;
    } else {
      this.setState({
        isNameInvalidate: false
      });
    }
    if (!this.inputFile.value) {
      this.setState({
        isFileInvalidate: true
      });
      isValid = false;
    } else {
      this.setState({
        isFileInvalidate: false
      });
    }

    if (!isValid) {
      return;
    }

    this.setState({
      isPending: true,
      isSuccess: false,
      isFailure: false
    });

    let bodyFormData = new FormData();

    bodyFormData.set("name", this.inputName.value);
    bodyFormData.append("plan", this.inputFile.files[0]);

    api({
      method: "post",
      url: "plans",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    })
      .then(() => {
        this.getList();
      })
      .catch(() => {
        this.setState({
          isPending: false,
          isSuccess: false,
          isFailure: true
        });
      });
  }

  render() {
    const {
      isPending,
      isSuccess,
      isFailure,
      list,
      isNameInvalidate,
      isFileInvalidate
    } = this.state;

    if (isPending) {
      return <div className="loading">Загрузка...</div>;
    }
    if (isFailure) {
      return <div className="failure">Ошибка загрузки данных</div>;
    }
    if (isSuccess) {
      let listTemplate = (
        <li className="plans-list__item">
          <div className="plans-list__item-content">Нет документов</div>
        </li>
      );
      if (list.length) {
        listTemplate = list.map(item => (
          <li key={item.id} className="plans-list__item">
            <div className="plans-list__item-content">{item.name}</div>
            <div className="plans-list__item-link">
              <Link to={`/${item.id}`}>Посмотреть</Link>
            </div>
          </li>
        ));
      }
      return (
        <div className="list-page">
          <div className="list-page__wrapper">
            <h1>Документы</h1>
            <div className="list-page__plans-wrapper">
              <div className="list-page__plans-list-wrapper">
                <ul className="plans-list">{listTemplate}</ul>
              </div>
              <div className="list-page__plans-download">
                <h3>Загрузить новый документ</h3>
                <div className="plans-download">
                  <div className="form">
                    <div className="form__item">
                      <label htmlFor="name" className="form__label">
                        Название
                      </label>
                      <input
                        ref={ref => (this.inputName = ref)}
                        type="text"
                        id="name"
                      />
                      {isNameInvalidate ? (
                        <div className="form__error">Введите название</div>
                      ) : null}
                    </div>
                    <div className="form__item">
                      <label htmlFor="file" className="form__label">
                        Файл
                      </label>
                      <input
                        ref={ref => (this.inputFile = ref)}
                        type="file"
                        id="file"
                      />
                      {isFileInvalidate ? (
                        <div className="form__error">Загрузите файл</div>
                      ) : null}
                    </div>
                    <button
                      type="button"
                      className="btn btn--block"
                      onClick={this.handlerSubmitNewPlan.bind(this)}
                    >
                      Загрузить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default List;
